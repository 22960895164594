import { useTranslation } from 'react-i18next';

import Asset from '@bluecodecom/common/ui/Asset';
import Typo from '@bluecodecom/common/ui/Typo';

const EuTab = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center px-4 pt-2 space-y-3 text-center">
      <Asset name="Bank" isBouncing dropShadow autoResizeViewHeight={30} />
      <Typo size="2xl" weight="700">
        {t('eu.title')}
      </Typo>
      <Typo weight="300">{t('eu.description')}</Typo>
    </div>
  );
};

export default EuTab;
