export type ConfigCountry = 'NONE' | 'DE' | 'AT' | 'EU';

export type ConfigRegionEuBank = {
  id?: string;
  name?: string;
};

export type Config = {
  allowed_countries?: ConfigCountry[] | null;
  bank_search_url?: string | null;
  feature_flags?: string[];
  multilanguage?: boolean;
  prepay_in_use?: boolean;
  mixpanel_token?: string | null;
  sentry_dsn?: string | null;
  preferred_country?: ConfigCountry | null;
  region_eu_bank?: ConfigRegionEuBank | null;
  wallet_id?: string | null;
  sdk_host?: string | null;
};

export enum FeatureFlag {
  Prepay = 'bank_search_prepay',
  EuTabOneCent = 'bank_search_eu_tab_one_cent',
}
