import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@bluecodecom/common/ui/Button';
import List from '@bluecodecom/common/ui/List';
import Typo from '@bluecodecom/common/ui/Typo';

import { banksService } from '@bluecodecom/bank-search-webview/features/banks';
import { configService } from '@bluecodecom/bank-search-webview/features/config';
import { trackingService } from '@bluecodecom/bank-search-webview/features/tracking';

import oneCentUrl from './assets/one_cent.png';

const OneCent = () => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (!configService.config.region_eu_bank?.id) {
      return;
    }

    trackingService.trackEvent('bank-search bank-selected eu', {
      id: configService.config.region_eu_bank.id,
    });

    document.location = `https://app.${configService.domain}/gateway/onboard/${configService.config.region_eu_bank.id}?jwt=${banksService.jwt}`;
  }, []);
  return (
    <div className="p-4">
      <List.Item
        disableInteractiveClassName
        logo={
          <List.Item.Logo containerSizeClassName="w-12 h-12" url={oneCentUrl} />
        }
        title={t('oneCent.title')}
        info={
          <div className="flex flex-col pt-4 space-y-4">
            <Typo weight="300">{t('oneCent.description1')}</Typo>
            <Typo weight="300">{t('oneCent.description2')}</Typo>
            <Button onClick={handleClick} size="small">
              {t('oneCent.title')}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default OneCent;
